<template>
    <div id="box">
        <Header :headerIndex="0"></Header>
        <div class="container">
            <div class="index">
                <swiper :options="swiperOption" ref="mySwiper" class="m1440">
                    <swiper-slide v-for="item in banner"><img :src="item.image_text" alt="" class="banner m1440" @click="gourl(item)"></swiper-slide>
                </swiper>
                <div class="" v-if="user">
                    <div class="m1340">
                        <div class="info_box flex-box mb20" >
                            <div class="flex-box info">

                                <img :src="user.avatar ? user.avatar : require('../assets/static/icon/avatar.png')"
                                    class="avatar" alt="" />
                                <div class="pl40">
                                    <div class=" flex-box fs24" :class="user.is_special==1?'name':''">{{ user.idnum }}</div>
                                    <div class="id fs18 pt20">ID: {{ user.id }}</div>
                                </div>
                            </div>
                            <div class="inventory flex-grow-1">
                                <div class="fs14 col5 pb15">YOUR INVENTORY</div>
                                <div class="flex-box flex-between">
                                    <div>
                                        <span class="fs18">{{ user.coins | numberToCurrency }}</span>
                                        M-Coins
                                    </div>
                                    <div>
                                        <span class="fs18">{{ user.rp | numberToCurrency }}</span>
                                        RP
                                    </div>
                                    <div>
                                        <span class="fs18">{{ user.pp | numberToCurrency }}</span>
                                        PP
                                    </div>
                                    <div>
                                        <span class="fs18">{{ user.ap | numberToCurrency }}</span>
                                        AP
                                    </div>
                                    <div>
                                        <span class="fs18">{{ user.ep | numberToCurrency }}</span>
                                        EP
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <router-link class="btn more" to="/market" >MORE+</router-link> -->
                    </div>
                </div>
                <div class="part2">
                    <div class="m1440 index_blog">
                        <div class="title tc">&nbsp;金蛇纳福 春节愉快&nbsp;</div>
                        <div class="introduction tc" style="text-align: left;line-height: 36px;font-size:16px;width:680px;padding-top:20px;">

                            SMA 全球执行委员会🌷携全体GEC<br/>
                            祝愿所有SMA家人✨在新的一年里<br/>
                            代币多多 业绩多多<br/>
                            城市发展多多 现金多多<br/>
                            2025焕发更耀眼的新光芒<br/><br/>

                            SMA 成就千万家庭<br/>
                            新增10城1000GEC<br/>
                            吉隆坡 巅峰相见<br/>
                            SMA<br/>
                            祝大家新年越花越有<br/>
                            平安富贵发大财<br/><br/>

                            SMA 全球执行委员会<br/>
                            01.29.2025
                        </div>
                    </div>
                </div>
                <div class="part2">
                    <div class="m1440 index_blog">
                        <div class="title tc">FAQ&BLOG</div>
                        <div class="introduction tc">Description of common problems. Description of common problems. </div>
                        <div class="flex-box flex-between faq_list tc">
                            <template v-for="(item, index) in articlelist">
                                <a v-if="!item.url" href="javascript:;" @click="godetail(item)" class="faq">
                                    <img :src="item.image_text" class="pics" alt="">
                                    <div class="fs24">{{ item.name }}</div>
                                </a>
                                <a v-else :href="item.url" class="faq">
                                    <img :src="item.image_text" class="pics" alt="">
                                    <div class="fs24">{{ item.name }}</div>
                                </a>
                            </template>
                            <!-- <a href="javascript:;" class="faq">
                                <img src="../assets/static/icon/pic2.png" class="pics" alt="">
                                <div class="fs24">FDISCOVERY</div>
                            </a>
                            <a href="javascript:;" class="faq">
                                <img src="../assets/static/icon/pic3.png" class="pics" alt="">
                                <div class="fs24">BLOG</div>
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<style scoped>

.container{
    background-image: url('../assets/static/images/xiangyun.png');
    background-repeat: repeat;
    background-size: 20%;
}
.index{
    background-image: url('../assets/static/images/spring-bg-all.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
}
.index .part2{padding:0 0 20px;margin:0;}
.index .swiper-container{height:340px;}
.footer{
    /*background:#c62a2a;*/
    background-image: url('../assets/static/images/xiangyun.png');
    background-repeat: repeat;
    background-size: 20%;
}
.footer.m1440 {width:auto;}

</style>

<script>
import cookie from "vue-cookies";
import { numberToCurrency } from "../assets/filters";
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/dist/css/swiper.css";
import "swiper/swiper-bundle.css";

export default {
    filters: {
        numberToCurrency
    },
    components: {
        swiper,
        swiperSlide
    },
    data() {
        return {
            price: '',
            articlelist: '',
            user: '',
            configs: '',
            swiperOption: {
                loop: true,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                // 显示分页
            },
            banner:''
        };
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper;
        }
    },
    mounted() {
        // current swiper instance
        // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
        console.log("this is current swiper instance object", this.swiper);
        // this.swiper.slideTo(3, 1000, false);
    },
    created() {
        this.article()
        this.getuser()
        this.config()

        this.getbanner()
    },
    methods: {
        gourl(item){
            if(item.url){
                window.location.href = item.url;

            }
        },
        getbanner(){
            this.$api.getbanner({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.banner = res.data
                } else {
                    // that.$message.error(res.msg);
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })

                }
            })
        },
        config() {
            this.$api.config({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.configs = res.data
                } else {
                    // that.$message.error(res.msg);

                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
            })
        },
        article() {
            this.$api.apiArticle({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.articlelist = res.data
                    console.log(this.articlelist)
                } else {
                    // that.$message.error(res.msg);
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
            })
        },
        getuser() {
            if (cookie.get('sma_token')) {
                this.$api.apiUserInfo({}).then(ret => {
                    if (ret.code == 1) {

                        this.user = ret.data.userinfo;

                    }
                })
            }


        },
        godetail(item) {
            // this.$router.push({ path: '/articledetail', query: { id: item.id } })
        }
    },
};
</script>
